body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body {
  -webkit-overflow-scrolling: touch !important;
}

.App {
  text-align: center;
  -webkit-overflow-scrolling: touch !important;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.colorPrimary {
  color: #fff;
  background-color: black;
}

.momentumScroll {
  -webkit-overflow-scrolling: touch !important;
  margin-bottom: 20px;
  padding-bottom: 20px;
}

.colorSecondary {
    color: #fff;
    background-color: black;
}
a {
  text-transform: capitalize;
  text-decoration: none;
}
@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

/*.RichEditor-root {
  background: #fff;
  border: 1px solid #ddd;
  font-family: 'Georgia', serif;
  font-size: 14px;
  padding: 15px;
}

.RichEditor-editor {
  border-top: 1px solid #ddd;
  cursor: text;
  font-size: 16px;
  margin-top: 10px;
}

.RichEditor-editor .public-DraftEditorPlaceholder-root,
.RichEditor-editor .public-DraftEditor-content {
  margin: 0 -15px -15px;
  padding: 15px;
}

.RichEditor-editor .public-DraftEditor-content {
  min-height: 100px;
  background-color: #DCDCDC;
  margin: 1em;
  !*border-right: 1em solid #696969;*!
}

.RichEditor-hidePlaceholder .public-DraftEditorPlaceholder-root {
  display: none;
}

.RichEditor-editor .RichEditor-blockquote {
  border-left: 5px solid #eee;
  color: #666;
  font-family: 'Hoefler Text', 'Georgia', serif;
  font-style: italic;
  margin: 16px 0;
  padding: 10px 20px;
}

.RichEditor-editor .public-DraftStyleDefault-pre {
  background-color: rgba(0, 0, 0, 0.05);
  font-family: 'Inconsolata', 'Menlo', 'Consolas', monospace;
  font-size: 16px;
  padding: 20px;
}

.RichEditor-controls {
  font-family: 'Helvetica', sans-serif;
  font-size: 14px;
  margin-bottom: 5px;
  user-select: none;
}

.RichEditor-styleButton {
  color: #999;
  cursor: pointer;
  margin-right: 16px;
  padding: 2px 0;
  display: inline-block;
}

.RichEditor-activeButton {
  color: #5890ff;
}

.align-right div {
  text-align: right;
}
.align-center div {
  text-align: center;
}
.align-left div {
  text-align: left;
}*/

.editor {
    box-sizing: border-box;
    border: 1px solid #ddd;
    cursor: text;
    padding: 4px;
    border-radius: 2px;
    /*margin-bottom: 2em;*/
    box-shadow: inset 0px 1px 8px -3px #ABABAB;
    background: #fefefe;
}

.editor .public-DraftEditor-content {
    /*height: fit-content !important;*/
    min-height: 100px;
}
/*.public-DraftEditor-content {*/
    /*height: fit-content !important;*/
/*}*/

.public-DraftStyleDefault-block {
    margin: 0;
}

.headlineButtonWrapper {
    display: inline-block;
}

.rdw-embedded-modal-size {
    display: none !important;;
}

.rdw-image-modal-size {
    display: none !important;;
}

.headlineButton {
    background: #fbfbfb;
    color: #888;
    font-size: 18px;
    border: 0;
    padding-top: 5px;
    vertical-align: bottom;
    /*height: 34px;*/
    /*width: 36px;*/
}

.headlineButton:hover,
.headlineButton:focus {
    background: #f3f3f3;
}

/*.addImagePopover {*/
    /*display: block;*/
    /*margin-top: 10px;*/
    /*background: #FFF;*/
    /*position: absolute;*/
    /*height: 54px;*/
    /*width: 300px;*/
    /*border-radius: 2px;*/
    /*padding: 10px;*/
    /*box-shadow: 0px 4px 30px 0px rgba(220,220,220,1);*/
    /*z-index: 1000;*/
/*}*/
/*.addImageClosedPopover {*/
    /*display: none;*/
/*}*/

.options{
    margin-bottom: 20px;
}
.addImage {
    background: #FFF;
    display: inline-block;
}

.addMediaPopover {
    margin-top: 10px;
    background: #FFF;
    position: absolute;
    height: 54px;
    width: 300px;
    left: 0;
    right:0;
    border-radius: 2px;
    padding: 10px;
    box-shadow: 0px 4px 30px 0px rgba(220,220,220,1);
    z-index: 1000;
}

.addMediaClosedPopover {
    display: none;
}

.addMediaButton{
    box-sizing: border-box;
    background: #fff;
    border: 1px solid #ddd;
    padding: 0;
    color: #888;
    margin: 0;
    border-radius: 1.5em;
    cursor: pointer;
    height: 1.5em;
    width: 2.5em;
    font-size: 1.5em;
    line-height: 1.2em;
    margin: 0;
}

.addMediaButton:focus {
    outline: 0; /* reset for :focus */
}

.addMediaButton:hover {
    background: #f3f3f3;
}

.addMediaButton:active {
    background: #e6e6e6;
}

.addMediaPressedButton {
    background: #ededed;
}

.addMediaBottomGradient {
    width: 100%;
    height: 1em;
    position: absolute;
    bottom: 0px;
    left: 0px;
    right: 0px;
    background-color: white;
    pointer-events: none;
    background: linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
}

.addMediaInput {
    box-sizing: border-box;
    border: 1px solid #ddd;
    cursor: text;
    padding: 4px;
    width: 78%;
    border-radius: 2px;
    margin-bottom: 1em;
    box-shadow: inset 0px 1px 8px -3px #ABABAB;
    background: #fefefe;
}

.addMediaConfirmButton {
    box-sizing: border-box;
    background: #fff;
    border: 1px solid #ddd;
    padding: 0;
    color: #888;
    margin: 0;
    border-radius: 2.1em;
    cursor: pointer;
    height: 2.1em;
    width: 18%;
    font-size: 1em;
    line-height: 2.1em;
    margin: 0;
    margin-left: 4%;
}

.addMediaConfirmButton {
    outline: 0; /* reset for :focus */
}

.addMediaConfirmButton:hover {
    background: #f3f3f3;
}

.addMediaConfirmButton:active {
    background: #e6e6e6;
}
