.editor {
    box-sizing: border-box;
    border: 1px solid #ddd;
    cursor: text;
    padding: 4px;
    border-radius: 2px;
    /*margin-bottom: 2em;*/
    box-shadow: inset 0px 1px 8px -3px #ABABAB;
    background: #fefefe;
}

.editor :global(.public-DraftEditor-content) {
    /*height: fit-content !important;*/
    min-height: 100px;
}
/*.public-DraftEditor-content {*/
    /*height: fit-content !important;*/
/*}*/

.public-DraftStyleDefault-block {
    margin: 0;
}

.headlineButtonWrapper {
    display: inline-block;
}

.rdw-embedded-modal-size {
    display: none !important;;
}

.rdw-image-modal-size {
    display: none !important;;
}

.headlineButton {
    background: #fbfbfb;
    color: #888;
    font-size: 18px;
    border: 0;
    padding-top: 5px;
    vertical-align: bottom;
    /*height: 34px;*/
    /*width: 36px;*/
}

.headlineButton:hover,
.headlineButton:focus {
    background: #f3f3f3;
}

/*.addImagePopover {*/
    /*display: block;*/
    /*margin-top: 10px;*/
    /*background: #FFF;*/
    /*position: absolute;*/
    /*height: 54px;*/
    /*width: 300px;*/
    /*border-radius: 2px;*/
    /*padding: 10px;*/
    /*box-shadow: 0px 4px 30px 0px rgba(220,220,220,1);*/
    /*z-index: 1000;*/
/*}*/
/*.addImageClosedPopover {*/
    /*display: none;*/
/*}*/

.options{
    margin-bottom: 20px;
}
.addImage {
    background: #FFF;
    display: inline-block;
}

.addMediaPopover {
    margin-top: 10px;
    background: #FFF;
    position: absolute;
    height: 54px;
    width: 300px;
    left: 0;
    right:0;
    border-radius: 2px;
    padding: 10px;
    box-shadow: 0px 4px 30px 0px rgba(220,220,220,1);
    z-index: 1000;
}

.addMediaClosedPopover {
    display: none;
}

.addMediaButton{
    box-sizing: border-box;
    background: #fff;
    border: 1px solid #ddd;
    padding: 0;
    color: #888;
    margin: 0;
    border-radius: 1.5em;
    cursor: pointer;
    height: 1.5em;
    width: 2.5em;
    font-size: 1.5em;
    line-height: 1.2em;
    margin: 0;
}

.addMediaButton:focus {
    outline: 0; /* reset for :focus */
}

.addMediaButton:hover {
    background: #f3f3f3;
}

.addMediaButton:active {
    background: #e6e6e6;
}

.addMediaPressedButton {
    background: #ededed;
}

.addMediaBottomGradient {
    width: 100%;
    height: 1em;
    position: absolute;
    bottom: 0px;
    left: 0px;
    right: 0px;
    background-color: white;
    pointer-events: none;
    background: linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
}

.addMediaInput {
    box-sizing: border-box;
    border: 1px solid #ddd;
    cursor: text;
    padding: 4px;
    width: 78%;
    border-radius: 2px;
    margin-bottom: 1em;
    box-shadow: inset 0px 1px 8px -3px #ABABAB;
    background: #fefefe;
}

.addMediaConfirmButton {
    box-sizing: border-box;
    background: #fff;
    border: 1px solid #ddd;
    padding: 0;
    color: #888;
    margin: 0;
    border-radius: 2.1em;
    cursor: pointer;
    height: 2.1em;
    width: 18%;
    font-size: 1em;
    line-height: 2.1em;
    margin: 0;
    margin-left: 4%;
}

.addMediaConfirmButton {
    outline: 0; /* reset for :focus */
}

.addMediaConfirmButton:hover {
    background: #f3f3f3;
}

.addMediaConfirmButton:active {
    background: #e6e6e6;
}